<template>
  <div class="container">
    <img :src="$getConfig('activityEndBg')" width="100%" alt="" />
    <ul class="panel">
      <li>
        <p class="p1">
          <span class="p2">{{ queryNum }}</span
          >次
        </p>
        <p class="p1">查询次数</p>
      </li>
      <li>
        <p class="p1">
          <span class="p2">{{ currentQueryNum }}</span
          >次
        </p>
        <p class="p1">当前已被您扫</p>
      </li>
    </ul>
    <BaseNav
      :indexs="[
        {
          index: 1,
          tracking: { userVisit: '42', sourceFlag: '6' }
        },
        {
          index: 2,
          tracking: { event: 'launch', userVisit: '42', sourceFlag: '7' }
        },
        {
          index: 3,
          tracking: { userVisit: '42', sourceFlag: '8' }
        }
      ]"
    />
  </div>
</template>

<script>
import preventBack from '@/mixins/prevent-browser-back'

import {
  selectCountOfScanQrcodeByQrcode,
  selectCountOfScanQrcodeByQrcodeAndOpenId
} from '@/api/index.js'

export default {
  name: 'ActivityEnd',
  mixins: [preventBack],
  data() {
    return {
      queryNum: '',
      currentQueryNum: ''
    }
  },
  methods: {
    //查询次数（活动结束）
    getSelectCountOfScanQrcodeByQrcode() {
      selectCountOfScanQrcodeByQrcode().then(({ data }) => {
        this.queryNum = data
      })
    },
    //当前已被您扫(活动已结束)
    getSelectCountOfScanQrcodeByQrcodeAndOpenId() {
      selectCountOfScanQrcodeByQrcodeAndOpenId().then(({ data }) => {
        this.currentQueryNum = data
      })
    }
  },
  created() {
    this.getSelectCountOfScanQrcodeByQrcode()
    this.getSelectCountOfScanQrcodeByQrcodeAndOpenId()
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .panel {
    position: absolute;
    right: 50px;
    top: 705px;
    display: flex;
    justify-content: center;
    width: 550px;
    li {
      flex: 1 1 auto;
      text-align: center;
      .p1 {
        font-size: 24px;
        color: #333;
      }
      .p2 {
        font-size: 52px;
        color: #e7471f;
      }
    }
  }
}
</style>
