var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('img',{attrs:{"src":_vm.$getConfig('activityEndBg'),"width":"100%","alt":""}}),_c('ul',{staticClass:"panel"},[_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.queryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("查询次数")])]),_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.currentQueryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("当前已被您扫")])])]),_c('BaseNav',{attrs:{"indexs":[
      {
        index: 1,
        tracking: { userVisit: '42', sourceFlag: '6' }
      },
      {
        index: 2,
        tracking: { event: 'launch', userVisit: '42', sourceFlag: '7' }
      },
      {
        index: 3,
        tracking: { userVisit: '42', sourceFlag: '8' }
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }